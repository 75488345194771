<script>
import { Row, Col, Card, Button } from 'ant-design-vue'
import apiTool from '@/command/apiTool'
import DrawerForm from '../../../components/DrawerForm'
import { getAction, putAction } from '@/command/netTool'
import FormView from '@/components/FormView'
import eventBus from '@/command/events'
const { Meta } = Card

export default {
  name: 'personalsetting',
  data() {
    return {
      active: 'basic',
      getCodeMsg: '获取验证码',
      timeOut: null,
      countdown: 60,
      getDisabled: false,
      formBasic: {
        username: null,
        avatar: null,
      },
      gradeC: {
        1: '#f8b3a1',
        2: '#ffad34',
        3: '#ffd699',
        4: '#a3e5c4',
      },
      gradeT: {
        1: '密码太弱啦！',
        2: '密码比较弱哦！',
        3: '密码安全！',
        4: '密码很强很安全！',
      },
      phone: JSON.parse(localStorage.getItem('userInfos'))?.phone || '',
    }
  },
  mounted() {
    this.getDataInit()
  },
  methods: {
    debounce(fn, delay) {
      if (this.timeDe) {
        clearTimeout(this.timeDe)
      }
      this.timeDe = setTimeout(() => {
        fn()
      }, delay)
    },
    onLogout() {
      this.$store.dispatch('Logout').then(() => {
        this.$router.push({ name: 'login' })
      })
    },
    getDataInit() {
      if (this.active === 'basic') {
        getAction('/admin/my/info', {}, '/bpi').then((res) => {
          let data = res.data.sysUser
          localStorage.setItem('userInfos', JSON.stringify(data))
          eventBus.$emit('userInfos', data)
          this.formBasic = {
            username: data.realName,
            avatar: data.avatar,
          }
        })
      } else {
      }
    },
    onBasicSubmit() {
      if (!this.formBasic.username) {
        this.$message.warning('请输入昵称！')
        return
      }
      let { username, avatar } = this.formBasic
      putAction(
        '/admin/my/updateUser',
        {
          realName: username,
          avatarUrl: avatar,
        },
        '/bpi'
      ).then((res) => {
        if (res.code == 200) {
          this.$message.success('修改成功')
          this.getDataInit()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    getCode(newPhone) {
      putAction('/admin/my/send/changePhone/sms?newPhone=' + newPhone, { newPhone }, '/bpi').then((e) => {
        if (e.code === 200) {
          this.$message.success('验证码已发送')
          this.getDisabled = true
          this.timeOut = setInterval(this.setCountdown, 1000)
        } else {
          this.$message.error(e.msg)
        }
      })
    },
    setCountdown() {
      if (this.countdown === 0) {
        this.getCodeNow()
      } else {
        this.getCodeMsg = this.countdown + 's后重新获取'
        this.getDisabled = true
        this.countdown--
      }
    },
    getCodeNow() {
      this.getCodeMsg = '获取验证码'
      this.getDisabled = false
      this.countdown = 60
      clearInterval(this.timeOut)
    },
    getDrawerFormPassword(source, formData) {
      if (source.newPassword) {
        getAction('/admin/my/queryPwdGrade?password=' + source.newPassword, {}, '/bpi').then((res) => {
          const { level } = res.data
          source.colorC = this.gradeC[level]
          source.colorT = this.gradeT[level]
        })
      }
      return [
        {
          form: [
            {
              name: '旧密码',
              type: 'input',
              key: 'oldPassword',
              cols: 24,
            },
            {
              name: '新密码',
              type: 'input',
              key: 'newPassword',
              cols: 24,
              props: {
                type: 'password',
                suffix: source.colorC && source.colorT,
              },
              styles: {
                border: source.colorC && `1px solid ${source.colorC}`,
              },
            },
            {
              value: '建议：长度不小于8位，且包含，大写英文字母、小写英文字母、数字和符号。',
              type: 'text',
              styles: {
                color: '#ff9901',
                fontSize: '14px',
                fontWeight: '400',
              },
            },
            {
              name: '确认密码',
              type: 'input',
              key: 'confirmPassword',
              cols: 24,
              props: {
                type: 'password',
              },
            },
          ],
        },
      ]
    },
    getDrawerFormPhone(source, formData) {
      return [
        {
          form: [
            {
              name: '原手机号',
              value: `已绑定手机号：+86 ${this.phone.substr(0, 3)}****${this.phone.substr(7)}`,
              type: 'text',
              styles: {
                color: 'rgb(170,170,170)',
                fontSize: '14px',
                fontWeight: '500',
              },
            },
            {
              name: '新手机号',
              type: 'row',
              cols: 24,
              gutter: 5,
              children: [
                {
                  label: '新手机号',
                  type: 'inputNumber',
                  key: 'newPhone',
                  cols: 20,
                },
                {
                  label: this.getCodeMsg,
                  type: 'button',
                  cols: 4,
                  props: {
                    disabled: this.getDisabled,
                    on: {
                      click: () => {
                        if (!source.newPhone) return
                        this.getCode(source.newPhone)
                      },
                    },
                  },
                },
              ],
            },
            {
              name: '验证码',
              type: 'input',
              key: 'code',
              cols: 24,
              props: {
                type: 'password',
              },
            },
          ],
        },
      ]
    },
    onPassword(source = {}) {
      apiTool.showDrawer({
        title: '修改密码',
        width: '720px',
        view: DrawerForm,
        viewProps: {
          data: () => this.getDrawerFormPassword(source),
          form: source,
        },
        success: ({ data, setHidden }) => {
          putAction('/admin/my/updatePwd', data, '/bpi').then((res) => {
            if (res.code == 200) {
              this.$message.success('修改成功，请重新登录')
              setHidden()
              this.onLogout()
            } else {
              this.$message.error(res.msg)
            }
          })
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '取消',
              onClick: close,
            },
            {
              name: '确认修改',
              type: 'primary',
              onClick: submit,
            },
          ]
        },
      })
    },
    onPhone(source = { newPhone: '' }) {
      apiTool.showDrawer({
        title: '修改手机号',
        width: '720px',
        view: DrawerForm,
        viewProps: {
          data: () => this.getDrawerFormPhone(source),
          form: source,
        },
        success: ({ data, setHidden }) => {
          let { code, newPhone } = data
          putAction(`/admin/my/changePhone?newPhone=${newPhone}&code=${code}`, data, '/bpi').then((res) => {
            if (res.code == 200) {
              this.$message.success('修改成功，请重新登录')
              setHidden()
              this.onLogout()
            } else {
              this.$message.error(res.msg)
            }
          })
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '取消',
              onClick: close,
            },
            {
              name: '确认修改',
              type: 'primary',
              onClick: submit,
            },
          ]
        },
      })
    },
    renderBasic() {
      let arrForm = [
        {
          name: '昵称',
          type: 'input',
          key: 'username',
          cols: 12,
          labelCol: { span: 4 },
          wrapperCol: { span: 20 },
          rules: [
            {
              required: true,
              type: 'string',
            },
          ],
        },
        {
          name: '更换头像',
          type: 'upload',
          key: 'avatar',
          labelCol: { span: 2 },
          wrapperCol: { span: 20 },
        },
      ]
      return (
        <Card title="基本设置">
          <FormView ref={'_form'} cols={24} data={arrForm} form={this.formBasic} />
          <div class="foot-btn-box">
            <Button type="primary" onClick={() => this.onBasicSubmit()}>
              更新基本信息
            </Button>
          </div>
        </Card>
      )
    },
    renderSecurity() {
      return (
        <Card title="安全设置" bodyStyle={{ padding: '15px 25px' }}>
          <div class="ml-2">
            <Meta title="账户密码" description="绑定手机，并设置密码，账号更安全" />
            <a onClick={() => this.onPassword()}>修改</a>
          </div>
          <div class="ml-2">
            <Meta
              title="绑定手机"
              description={`已绑定手机号：+86 ${this.phone.substr(0, 3)}****${this.phone.substr(7)}`}
            />
            <a onClick={() => this.onPhone()}>修改</a>
          </div>
        </Card>
      )
    },
    renderLeft() {
      return (
        <Card title="个人设置" bodyStyle={{ padding: '0' }}>
          <div onClick={() => (this.active = 'basic')} class={{ hactive: this.active == 'basic' }}>
            <div class="ml">
              <Meta title="基本信息" description="个人账户信息设置" />
            </div>
          </div>
          <div onClick={() => (this.active = 'security')} class={{ hactive: this.active == 'security' }}>
            <div class="ml">
              <Meta title="安全设置" description="密码手机等设置" />
            </div>
          </div>
        </Card>
      )
    },
    renderRight() {
      if (!this.active) return null
      if (this.active === 'basic') {
        return this.renderBasic()
      } else {
        return this.renderSecurity()
      }
    },
  },
  render() {
    return (
      <Row gutter={20}>
        <Col span={6}>{this.renderLeft()}</Col>
        <Col span={18}>{this.renderRight()}</Col>
      </Row>
    )
  },
  renderError(h, error) {
    console.log('报错', error)
  },
}
</script>
<style lang="less" scoped>
@import './index.less';
</style>
